// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../assets/img/login_banner_tablet.svg");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../assets/img/login_banner.svg");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "[data-v-24f17b1e] .v-label{color:#1d1d4e!important;font-size:16px!important;font-weight:600!important}[data-v-24f17b1e] .v-label.error--text{color:#1d1d4e!important}hr[data-v-24f17b1e]{border:0;border-color:#ccc #e0e0e0 #e0e0e0;border-top:1px solid #e0e0e0}.login-baner-sm-up[data-v-24f17b1e]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");height:256px}.login-baner-lg-up[data-v-24f17b1e],.login-baner-sm-up[data-v-24f17b1e]{background-position:50%;background-size:cover}.login-baner-lg-up[data-v-24f17b1e]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ")}.login-panel-sm-up[data-v-24f17b1e]{max-width:472px}.login-panel-lg-up[data-v-24f17b1e]{max-width:350px}.v-text-field[data-v-24f17b1e] .v-text-field__slot>input{color:#1d1d4e;font-size:14px;font-weight:600;margin-left:5px}.v-text-field[data-v-24f17b1e] .v-input__slot{background-color:transparent!important;margin-bottom:0}.v-text-field[data-v-24f17b1e] .v-input__slot>fieldset{background-color:#fff!important;height:54px}.v-text-field[data-v-24f17b1e] .v-input__slot>.v-text-field__slot{height:48px!important}.v-text-field[data-v-24f17b1e] .v-input__slot>.v-input__append-inner{margin-top:13px;z-index:10}.v-text-field[data-v-24f17b1e] .v-input__slot>.v-text-field__slot>label{color:#1d1d4e;font-size:12px;font-weight:600}.checkbox-label[data-v-24f17b1e] .v-label{color:#1d1d4e;font-size:14px;font-weight:400;white-space:nowrap}.account-activation-snackbar[data-v-24f17b1e] .v-snack__wrapper{border-bottom-right-radius:8px;border-left:4px solid #d64938!important;border-top-right-radius:8px;box-shadow:0 8px 16px 4px rgba(130,49,137,.04)!important}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
module.exports = ___CSS_LOADER_EXPORT___;
