import {
  ComplimentInt,
  SuggestLessonInt,
  SuggestCourseInt,
  HobbyIcon,
  Hobby,
} from '../types/interfaces';
import { ONBOARDING_STATUS } from '../types/enum';

export const complimentListMock: ComplimentInt[] = [
  {
    complimentId: 1,
    name: 'Wspaniały Mówca',
    icon: '/img/icons/ico_progress_01.svg',
  },
  {
    complimentId: 2,
    name: 'Wybitny Pisarz',
    icon: '/img/icons/ico_progress_02.svg',
  },
  {
    complimentId: 3,
    name: 'Dociekliwy Czytelnik',
    icon: '/img/icons/ico_progress_03.svg',
  },
  {
    complimentId: 4,
    name: 'Uważny Słuchacz',
    icon: '/img/icons/ico_progress_04.svg',
  },
  {
    complimentId: 5,
    name: 'Wnikliwy Badacz',
    icon: '/img/icons/ico_progress_05.svg',
  },
];

export const suggestLessonMock: SuggestLessonInt[] = [
  {
    courseId: 1,
    image: '/img/baners/suggest_lesson.jpg',
    name: 'Nauka gry na gitarze online',
    promo: 50,
  },
  {
    courseId: 2,
    image: '/img/baners/suggest_lesson.jpg',
    name: 'Ruby on Rails — A web-app framework',
    promo: 25,
  },
];

export const suggestCourseMock: SuggestCourseInt[] = [
  {
    courseId: 1,
    image: '/img/chess.png',
    name: 'Chcesz kontynuować?',
    student: {
      profileId: 1,
      name: 'Zosia',
      avatar:
        'https://firebasestorage.googleapis.com/v0/b/tutore-portal.appspot.com/o/avatars%2Favatar-29.svg?alt=media',
    },
    continue: true,
    description:
      'Jest już po lekcji próbnej Szachy <br><b>Chcesz kontynuować lekcje?</b>',
  },
  {
    courseId: 2,
    image: '/img/rysunek.png',
    name: 'Rysunek',
    price: 25,
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Condimentum leo in tellus',
  },
  {
    courseId: 3,
    image: '../assets/images/second_banner.jpg',
    name: 'Język polski',
    price: 25,
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Condimentum leo in tellus',
  },
];

export const courseGroupSummaryMock = [
  {
    img: '/img/book.png',
    text: 'Przedmiot',
    boldText: 'Język angielski asdadadasd',
  },
  {
    img: '/img/profile-2user.png',
    text: 'Liczebność grupy',
    boldText: 'Max 12 osób',
  },
  {
    img: '/img/calendar.png',
    text: 'Kursy w trakcie',
    boldText: '2/36',
  },
  {
    img: '/img/award.png',
    text: 'Cel kursu',
    boldText: 'Konwersacja',
  },
];

export const courseIndividualSummaryMock = [
  {
    img: 'book.png',
    text: 'Przedmiot',
    boldText: 'Język angielski zxczxczxc',
  },
  {
    img: 'profile-2user.png',
    text: 'Łącznie uczniów',
    boldText: '36 osób',
  },
  {
    img: 'calendar.png',
    text: 'Potwierdzone lekcje',
    boldText: '143',
  },
  {
    img: 'award.png',
    text: 'Specjalizacja',
    boldText: 'Konwersacja',
  },
];

export const aboutTeacherMock = [
  {
    headers: 'Wykształcenie',
    content:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Posuere non dui sagittis vitae orci id. Cras ultrices cursus lorem ultricies lectus a vulputate maecenas velit. Orci libero sit enim, odio proin vel leo. Sed nullam leo, scelerisque mi sit tellus cursus. Morbi nulla ultrices placerat eget duis metus dolor tortor, vitae.',
  },
  {
    headers: 'Metody nauczania',
    content:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Posuere non dui sagittis vitae orci id. Cras ultrices cursus lorem ultricies lectus a vulputate maecenas velit. Orci libero sit enim, odio proin vel leo. Sed nullam leo, scelerisque mi sit tellus cursus. Morbi nulla ultrices placerat eget duis metus dolor tortor, vitae.',
  },
  {
    headers: 'Certifykaty',
    content:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Posuere non dui sagittis vitae orci id. Cras ultrices cursus lorem ultricies lectus a vulputate maecenas velit. Orci libero sit enim, odio proin vel leo. Sed nullam leo, scelerisque mi sit tellus cursus. Morbi nulla ultrices placerat eget duis metus dolor tortor, vitae.',
  },
];

export const courseNumbersIndividualMock = [
  {
    numberInformation: '1h',
    header: 'Czas trwania lekcji',
    content:
      'Lekcja próbna trwa jedną godzinę, tak jak każda kolejna lekcja w kursie. Dzięki temu jesteś w stanie ocenić zajęcia.',
    numberColor: '#5F48Ef',
    numberBackgroundColor: 'rgba(95, 72, 239, 0.03)',
  },
  {
    numberInformation: '32zł',
    header: '10% zniżki na lekcję próbną',
    content:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Et egestas laoreet dui aliquam elit leo ultrices dis ultrices.',
    numberColor: '#F72585',
    numberBackgroundColor: 'rgba(247, 37, 133, 0.03)',
  },
  {
    numberInformation: '37',
    header: 'Przeprowadzonych lekcji',
    content:
      'Katarzyna W. ma już za sobą 37 lekcji próbnych! Imponująca liczba prawda? Poznajcie się bliżej!',
    numberColor: '#1EA3B9',
    numberBackgroundColor: 'rgba(30, 163, 185, 0.03)',
  },
];

export const skilsMock = [
  {
    text: 'Sposób prowadzenia zajęć',
    color: '#1EA3B9',
    progress: '92',
  },
  {
    text: 'Omawiany materiał',
    color: '#5F48EF',
    progress: '88',
  },
  {
    text: 'Różnorodność materiałów',
    color: '#F6CB07',
    progress: '83',
  },
  {
    text: 'Efekty kursu',
    color: '#0098DB',
    progress: '88',
  },
];

export const daysMock = [
  {
    dayName: 'Poniedziałek',
    dayNameShortcut: 'Pn',
    date: '13 listopada',
    dateShortcut: '13 lis',
    hours: [
      {
        hour: '09:00 - 10:00',
        slots: 0,
      },
      {
        hour: '11:00 - 12:00',
        slots: 0,
      },
      {
        hour: '12:00 - 13:00',
        slots: 2,
      },
      {
        hour: '13:00 - 14:00',
        slots: 5,
      },
      {
        hour: '15:00 - 16:00',
        slots: 0,
      },
    ],
  },
  {
    dayName: 'Wtorek',
    dayNameShortcut: 'Wt',
    date: '14 listopada',
    dateShortcut: '14 lis',
    hours: [
      {
        hour: '09:00 - 10:00',
        slots: 1,
      },
      {
        hour: '11:00 - 12:00',
        slots: 0,
      },
      {
        hour: '12:00 - 13:00',
        slots: 3,
      },
      {
        hour: '13:00 - 14:00',
        slots: 7,
      },
      {
        hour: '15:00 - 16:00',
        slots: 5,
      },
    ],
  },
  {
    dayName: 'Środa',
    dayNameShortcut: 'Śr',
    date: '15 listopada',
    dateShortcut: '15 lis',
    hours: [
      {
        hour: '09:00 - 10:00',
        slots: 2,
      },
      {
        hour: '11:00 - 12:00',
        slots: 0,
      },
      {
        hour: '12:00 - 13:00',
        slots: 3,
      },
      {
        hour: '13:00 - 14:00',
        slots: 0,
      },
      {
        hour: '15:00 - 16:00',
        slots: 6,
      },
    ],
  },
  {
    dayName: 'Czwartek',
    dayNameShortcut: 'Cz',
    date: '16 listopada',
    dateShortcut: '16 lis',
    hours: [
      {
        hour: '09:00 - 10:00',
        slots: 2,
      },
      {
        hour: '11:00 - 12:00',
        slots: 3,
      },
      {
        hour: '12:00 - 13:00',
        slots: 0,
      },
      {
        hour: '13:00 - 14:00',
        slots: 0,
      },
      {
        hour: '15:00 - 16:00',
        slots: 1,
      },
    ],
  },
  {
    dayName: 'Piątek',
    dayNameShortcut: 'Pt',
    date: '17 listopada',
    dateShortcut: '17 lis',
    hours: [
      {
        hour: '09:00 - 10:00',
        slots: 4,
      },
      {
        hour: '11:00 - 12:00',
        slots: 1,
      },
      {
        hour: '12:00 - 13:00',
        slots: 7,
      },
      {
        hour: '13:00 - 14:00',
        slots: 5,
      },
      {
        hour: '15:00 - 16:00',
        slots: 2,
      },
    ],
  },
  {
    dayName: 'Sobota',
    dayNameShortcut: 'Sb',
    date: '18 listopada',
    dateShortcut: '18 lis',
    hours: [
      {
        hour: '09:00 - 10:00',
        slots: 0,
      },
      {
        hour: '11:00 - 12:00',
        slots: 0,
      },
      {
        hour: '12:00 - 13:00',
        slots: 0,
      },
      {
        hour: '13:00 - 14:00',
        slots: 0,
      },
      {
        hour: '15:00 - 16:00',
        slots: 4,
      },
    ],
  },
];

export const individualCoursesMock = [
  {
    id: 12,
    title: 'Kasia W.',
    description:
      'Cześć! Mam na imię Klaudia i jestem certyfikowanym nauczycielem języka angielskiego. \nPrzygotowuję dzieci i mł...',
    stundentsCount: 10,
  },
  {
    id: 13,
    title: 'Piotr S.',
    description:
      'Cześć! Mam na imię Piotr i jestem certyfikowanym nauczycielem języka angielskiego. \nPrzygotowuję dzieci i mł...',
    stundentsCount: 10,
  },
  {
    id: 15,
    title: 'Patrycja C.',
    description:
      'Cześć! Mam na imię Patrycja i jestem certyfikowanym nauczycielem języka angielskiego. \nPrzygotowuję dzieci i mł...',
    stundentsCount: 10,
  },
  {
    id: 16,
    title: 'Staszek P.',
    description:
      'Cześć! Mam na imię Staszek i jestem certyfikowanym nauczycielem języka angielskiego. \nPrzygotowuję dzieci i mł...',
    stundentsCount: 10,
  },
  {
    id: 17,
    title: 'Agata L.',
    description:
      'Cześć! Mam na imię Agata i jestem certyfikowanym nauczycielem języka angielskiego. \nPrzygotowuję dzieci i mł...',
    stundentsCount: 10,
  },
  {
    id: 18,
    title: 'Ula K.',
    description:
      'Cześć! Mam na imię Ula i jestem certyfikowanym nauczycielem języka angielskiego. \nPrzygotowuję dzieci i mł...',
    stundentsCount: 10,
  },
  {
    id: 19,
    title: 'Ula K.',
    description:
      'Cześć! Mam na imię Klaudia i jestem certyfikowanym nauczycielem języka angielskiego. \nPrzygotowuję dzieci i mł...',
    stundentsCount: 10,
  },
  {
    id: 20,
    title: 'Ula K.',
    description:
      'Cześć! Mam na imię Klaudia i jestem certyfikowanym nauczycielem języka angielskiego. \nPrzygotowuję dzieci i mł...',
    stundentsCount: 10,
  },
  {
    id: 21,
    title: 'Ula K.',
    description:
      'Cześć! Mam na imię Klaudia i jestem certyfikowanym nauczycielem języka angielskiego. \nPrzygotowuję dzieci i mł...',
    stundentsCount: 10,
  },
  {
    id: 22,
    title: 'Ula K.',
    description:
      'Cześć! Mam na imię Klaudia i jestem certyfikowanym nauczycielem języka angielskiego. \nPrzygotowuję dzieci i mł...',
    stundentsCount: 10,
  },
];

export const videoMock =
  'https://firebasestorage.googleapis.com/v0/b/tutore-portal.appspot.com/o/videos%2Frysunek.mp4?alt=media';

export const availableHobbies: HobbyIcon[] = [
  { name: 'rysunek', value: 'drawing', icon: '✏️' },
  { name: 'gotowanie', value: 'cooking', icon: '🍳' },
  { name: 'szachy', value: 'chess', icon: '♟' },
  { name: 'gry komputerowe', value: 'computer', icon: '🎮' },
  { name: 'nauka języków', value: 'languages', icon: '🇬🇧' },
  { name: 'zwierzęta', value: 'animals', icon: '🦮' },
  { name: 'sport', value: 'sport', icon: '🚴🏻‍♀️️' },
  { name: 'podróże', value: 'travels', icon: '🏝' },
  { name: 'gry planszowe', value: 'board', icon: '🧩' },
  { name: 'jazda konna', value: 'horse', icon: '🏇🏼' },
  { name: 'YouTube', value: 'youtube', icon: '🖥' },
  { name: 'grafika komputerowa', value: 'graphics', icon: '👩🏻‍🎨' },
  { name: 'gra na instrumencie', value: 'instrument', icon: '🎻' },
  { name: 'książki', value: 'books', icon: '📖' },
  { name: 'muzyka', value: 'music', icon: '🎶' },
  { name: 'matematyka', value: 'math', icon: '📏' },
  { name: 'piłka nożna', value: 'football', icon: '⚽️' },
  { name: 'śpiew', value: 'singing', icon: '🎤' },
  { name: 'taniec', value: 'dance', icon: '💃🏼' },
  { name: 'inne', value: 'other', icon: '🌟' },
];

export const hobbyImages: Hobby[] = [
  {
    id: 0,
    name: 'rysunek',
    value: 'drawing',
    changedName: 'Rysunkiem',
    imgSrc: require(`@/assets/images/onboarding/hobbies/rysunek.png`),
  },
  {
    id: 1,
    name: 'gotowanie',
    value: 'cooking',
    changedName: 'Gotowaniem',
    imgSrc: require(`@/assets/images/onboarding/hobbies/gotowanie.png`),
  },
  {
    id: 2,
    name: 'szachy',
    value: 'chess',
    changedName: 'Grą w szachy',
    imgSrc: require(`@/assets/images/onboarding/hobbies/szachy.png`),
  },
  {
    id: 3,
    name: 'nauka języków',
    value: 'languages',
    changedName: 'Nauką języków',
    imgSrc: require(`@/assets/images/onboarding/hobbies/jezyki_obce.png`),
  },
  {
    id: 4,
    name: 'zwierzęta',
    value: 'animals',
    changedName: 'Zwierzętami',
    imgSrc: require(`@/assets/images/onboarding/hobbies/zwierzeta.png`),
  },
  {
    id: 5,
    name: 'sport',
    value: 'sport',
    changedName: 'Sportem',
    imgSrc: require(`@/assets/images/onboarding/hobbies/sport.png`),
  },
  {
    id: 6,
    name: 'podróże',
    value: 'travels',
    changedName: 'Podróżami',
    imgSrc: require(`@/assets/images/onboarding/hobbies/podroze.png`),
  },
  {
    id: 7,
    name: 'jazda konna',
    value: 'horse',
    changedName: 'Jazdą konną',
    imgSrc: require(`@/assets/images/onboarding/hobbies/jazda_konna.png`),
  },
  {
    id: 8,
    name: 'książki',
    value: 'books',
    changedName: 'Książkami',
    imgSrc: require(`@/assets/images/onboarding/hobbies/ksiazki.png`),
  },
  {
    id: 9,
    name: 'muzyka',
    value: 'music',
    changedName: 'Muzyką',
    imgSrc: require(`@/assets/images/onboarding/hobbies/muzyka.png`),
  },
  {
    id: 10,
    name: 'matematyka',
    value: 'math',
    changedName: 'Matematyką',
    imgSrc: require(`@/assets/images/onboarding/hobbies/matematyka.png`),
  },
  {
    id: 11,
    name: 'YouTube',
    value: 'youtube',
    changedName: 'YouTube',
    imgSrc: require(`@/assets/images/onboarding/hobbies/youtube.png`),
  },
  {
    id: 12,
    name: 'gry planszowe',
    value: 'board',
    changedName: 'Grami planszowymi',
    imgSrc: require(`@/assets/images/onboarding/hobbies/gry_planszowe.png`),
  },
  {
    id: 13,
    name: 'gry komputerowe',
    value: 'computer',
    changedName: 'Grami komputerowymi',
    imgSrc: require(`@/assets/images/onboarding/hobbies/gry_komputerowe.png`),
  },
  {
    id: 14,
    name: 'gra na instrumencie',
    value: 'instrument',
    changedName: 'Grą na instrumencie',
    imgSrc: require(`@/assets/images/onboarding/hobbies/gra_na_instrumencie.png`),
  },
  {
    id: 15,
    name: 'grafika komputerowa',
    value: 'graphics',
    changedName: 'Grafiką komputerową',
    imgSrc: require(`@/assets/images/onboarding/hobbies/grafika_komputerowa.png`),
  },
  {
    id: 16,
    name: 'piłka nożna',
    value: 'football',
    changedName: 'Piłką nożną',
    imgSrc: require(`@/assets/images/onboarding/hobbies/pilka_nozna.png`),
  },
  {
    id: 17,
    name: 'śpiew',
    value: 'singing',
    changedName: 'Śpiewem',
    imgSrc: require(`@/assets/images/onboarding/hobbies/spiew.png`),
  },
  {
    id: 18,
    name: 'taniec',
    value: 'dance',
    changedName: 'Tańcem',
    imgSrc: require(`@/assets/images/onboarding/hobbies/taniec.png`),
  },
  {
    id: 19,
    name: 'inne',
    value: 'other',
    changedName: 'Inne',
    imgSrc: require(`@/assets/images/onboarding/hobbies/inne.png`),
  },
];

export const goodPhotosExamples: string[] = [
  require('@/assets/images/onboarding/media/good_photo_01.jpg'),
  require('@/assets/images/onboarding/media/good_photo_02.jpg'),
  require('@/assets/images/onboarding/media/good_photo_03.jpg'),
];

export const badPhotosExamples: string[] = [
  require('@/assets/images/onboarding/media/wrong_photo_01.jpg'),
  require('@/assets/images/onboarding/media/wrong_photo_02.jpg'),
  require('@/assets/images/onboarding/media/wrong_photo_03.jpg'),
];

export const availabilitySelectableHours: string[] = [
  '00:00',
  '00:30',
  '01:00',
  '01:30',
  '02:00',
  '02:30',
  '03:00',
  '03:30',
  '04:00',
  '04:30',
  '05:00',
  '05:30',
  '06:00',
  '06:30',
  '07:00',
  '07:30',
  '08:00',
  '08:30',
  '09:00',
  '09:30',
  '10:00',
  '10:30',
  '11:00',
  '11:30',
  '12:00',
  '12:30',
  '13:00',
  '13:30',
  '14:00',
  '14:30',
  '15:00',
  '15:30',
  '16:00',
  '16:30',
  '17:00',
  '17:30',
  '18:00',
  '18:30',
  '19:00',
  '19:30',
  '20:00',
  '20:30',
  '21:00',
  '21:30',
  '22:00',
  '22:30',
  '23:00',
  '23:30',
];

export const availableHours: string[] = [
  '00:00',
  '01:00',
  '02:00',
  '03:00',
  '04:00',
  '05:00',
  '06:00',
  '07:00',
  '08:00',
  '09:00',
  '10:00',
  '11:00',
  '12:00',
  '13:00',
  '14:00',
  '15:00',
  '16:00',
  '17:00',
  '18:00',
  '19:00',
  '20:00',
  '21:00',
  '22:00',
  '23:00',
];

export const onboardingStatuses: string[] = [
  ONBOARDING_STATUS.ACCEPTED,
  ONBOARDING_STATUS.FILLED,
  ONBOARDING_STATUS.FIXED,
  ONBOARDING_STATUS.STARTED,
  ONBOARDING_STATUS.TO_BE_FIXED,
];

export const serviceTranslations = {
  'gitara ONLINE': 'Guitar ONLINE',
  'programowanie ONLINE': 'Programming ONLINE',
  'matematyka ONLINE': 'Mathematics ONLINE',
  'angielski ONLINE': 'English ONLINE',
  'grafika ONLINE': 'Graphics ONLINE',
  'rysunek ONLINE': 'Drawing ONLINE',
  'matematyka z Minecraftem ONLINE': 'Mathematics with Minecraft',
  "LET'S TALK angielski ONLINE": "English for Kids - Let's Talk Conversations",
  'MAT Egzamin 8 ONLINE': 'MAT Eighth grade exam',
  'ANG Egzamin 8 ONLINE': 'ENG Eighth grade exam',
  'szachy ONLINE': 'Chess ONLINE',
  'Grafika MarkWeb ONLINE': 'Graphics MarkWeb ONLINE',
  'Grafika RysFot ONLINE': 'Graphics RysFot ONLINE',
  'POL Egzamin 8 ONLINE': 'POL Eighth grade exam',
  'grafika DOROŚLI': 'Graphics ADULTS',
  'angielski DOROŚLI': 'English ADULTS',
  'rysunek DOROŚLI': 'Drawing ADULTS',
  'MAX MAT Egzamin 8': 'MAX MAT Eighth grade exam',
  'MAX ANG Egzamin 8': 'MAX Eighth grade exam',
  'MAX POL Egzamin 8': 'MAX POL Eighth grade exam',
  Bandlab: 'Bandlab',
  'Tworzenie gier': 'Game Development',
  'Digital Painting': 'Digital painting',
  'Grafika ArchGraf3D': 'Graphics ArchGraf3D',
  'ANG Matura': 'ENG Matura exam',
  'MAX ANG Matura': 'MAX ENG Matura exam',
  'MAX MAT Matura': 'MAX MAT Matura exam',
  'POL Matura': 'POL Matura exam',
  'MAX POL Matura': 'MAX POL Matura exam',
  'MAT Matura': 'MAT Matura exam',
  'Business English DOROŚLI': 'Business English ADULTS',
  'English Chats DOROŚLI': 'English Chats ADULTS',
  'Fast&Smart_A1': '',
  'Fast&Smart_A2': '',
  'Fast&Smart_B1': '',
  'Fast&Smart_B2': '',
  "Konwersacje Let's Talk": "Let's Talk conversations",
  "Konwersacje Let's Talk dla dorosłych":
    "Let's Talk - conversations for adults",
  'Korepetycje z języka angielskiego': 'English tutoring',
  'Język angielski - Super Uczeń': 'English - Super Student',
  'Język angielski - egzamin ósmoklasisty': 'English eighth grade exam',
  'Język angielski - matura': 'English matura exam at basic level',
  'Język angielski - językowa zdolniacha': 'Great student',
  'Język angielski - konwersacje dla dorosłych':
    'English - conversations for adults',
  "Język angielski - konwersacje Let's Talk":
    "English - Let's Talk conversations",
  'Język angielski korepetycje': 'English tutoring',
  'Angielski biznesowy dorośli - Business English':
    'Business course for adults',
  'Business English - angielski w pracy i biznesie': 'Business course',
  'Matematyka - korepetycje': 'Mathematics tutoring',
  'Matematyka - egzamin ósmoklasisty': 'Mathematics eighth grade exam',
  'Matematyka - matura': 'Mathematics matura exam at basic level',
  'Matematyka - Super Uczeń': 'Mathematics - Super Student',
  'Matematyka - mistrz matematyki': 'Mathematics - master of mathematics',
  'Język polski - egzamin ósmoklasisty': 'Polish eighth grade exam',
  'Język polski - matura': 'Polish matura exam at basic level',
  'Język polski - korepetycje': 'Polish tutoring',
  'Korepetycje - język polski': 'Polish tutoring',
  'Gitarowe pierwsze kroki': 'Gitarowe pierwsze kroki',
  'Gitara średnio zaawansowany': 'Gitara średnio zaawansowany',
  'Graficzne ABC': 'Graficzne ABC',
  'Podstawy ilustracji i fotografii': 'Basics of illustration and photography',
  'W świecie grafiki 3D': 'Computer graphics 3D',
  'Grafika od postaw dla dorosłych': 'Computer graphics for adults',
  'Szachy - poziom 0': 'Chess - level 0',
  'Szachy - poziom1': 'Chess - level 1',
  'Szachy - poziom 2': 'Chess - level 2',
  'Mistrz rysunku kreatywnego': 'Master of creative painting',
  'Tajniki rysunku': 'Secrets to drawing',
  'Rysunek stacjonarny': 'Drawing on-site',
  'Angielski stacjonarny': 'English on-site',
  'Gitara stacjonarny': 'Guitar on-site',
  'Matematyka z Minecraftem stacjonarny': 'Mathematics with Minecraft on-site',
  'Programowanie stacjonarny': 'Programming on-site',
  'Szachy stacjonarny': 'Chess on-site',
  'Grafika stacjonarny': 'Graphics on-site',
};

export const stationaryServices: number[] = [61, 62, 63, 64, 65, 66, 67, 83];
