
import Vue, { defineComponent } from 'vue';
import { mdiGoogle } from '@mdi/js';
import InlineSvg from 'vue-inline-svg';
import LocaleChanger from '../components/LocaleChanger.vue';
import { Payloads } from '../types/payloads';
import { ProfileState } from '../store/profile/stateType';
import { ProfileType, ONBOARDING_STATUS } from '../types/enum';
import { AccountState } from '../store/account/stateType';
import { CommonState } from '../store/common/stateType';
import slugs from '../locales/slugs';

import { TTextField } from '../node_modules/@musicmore/tutore-components/';
import { onboardingStatuses } from '../mock/index';
import { AfterTrialService } from '../types/interfaces';
import { Profile, OwsAgreements } from '~/types/backend/models';

export default defineComponent({
  name: 'LoginPage',
  components: {
    InlineSvg,
    LocaleChanger,
    TTextField,
  },
  data() {
    return {
      loadingSendAccountActivationEmail: false,
      mdiGoogle,
      rembemerMe: false,
      loading: false,
      valid: true,
      showPassword: false,
      email: '',
      emailRules: [
        (v: string) =>
          !!v || this.$t('COMMON.ERRORS.EMAIL.EMAIL_REQUIRED').toString(),
        (v: string) => {
          const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
          return (
            emailPattern.test(v.trim()) ||
            this.$t('COMMON.ERRORS.EMAIL.EMAIL_INCORRECT').toString()
          );
        },
      ],
      password: '',
      passwordRules: [
        (v: string) =>
          !!v || this.$t('COMMON.ERRORS.PASSWORD.PASSWORD_REQUIRED').toString(),
      ],
      profilesForGrade: [] as Profile[],
      accountActivationSnackbar: false,
      accountActivationLinkExpiredSnackbar: false,
      slug: slugs as any,
      onboardingStatus: '',
      completedTrialsData: [] as AfterTrialService[],
      trialDataToRedirect: {} as AfterTrialService | undefined,
      owsData: {} as OwsAgreements,
      showOwsPopup: false,
    };
  },
  computed: {
    account(): AccountState['account'] {
      return this.$store.state.account.account;
    },
    isAccountData(): AccountState['isAccountData'] {
      return this.$store.state.account.isAccountData;
    },
    redirectTo(): CommonState['redirectTo'] {
      return this.$store.state.common.redirectTo;
    },
    profiles(): ProfileState['profiles'] {
      return this.$store.state.profile.profiles;
    },
    passwordType(): string {
      return this.showPassword ? 'text' : 'password';
    },
    token(): CommonState['token'] {
      return this.$store.state.common.token;
    },
    enrollProfile(): Profile {
      return this.$store.state.profile.enrollProfile;
    },
    activationError(): boolean {
      return this.$store.state.account.activationError;
    },
    activationSuccess(): boolean {
      return this.$store.state.account.activationSuccess;
    },
  },
  watch: {
    account: {
      async handler(newVal: unknown | undefined) {
        if (newVal != null) {
          await this.$store.dispatch('account/fetchInformations');
          await this.fetchProfiles();
        }
      },
      immediate: true,
    },
    $route: {
      handler() {
        this.prepareData();
      },
      deep: true,
      immediate: true,
    },
  },
  created() {
    this.prepareData();
  },
  methods: {
    prepareData() {
      this.email = (this.$route.query.email || '').toString();
      if (this.activationError) {
        this.$store.commit('account/setActivationError', false);
        this.accountActivationLinkExpiredSnackbar = true;
      }
      if (this.activationSuccess) {
        this.$store.commit('account/setActivationSuccess', false);
        this.$nextTick(() => {
          Vue.notify({
            text: this.$t('PAGES.LOGIN.ACCOUNT_ACTIVATED').toString(),
            type: 'success',
          });
        });
      }
    },
    redirectToLogin() {
      this.$router.push({ path: `${this.localePath('Login')}/` });
    },
    async fetchProfiles() {
      const accountClaims = await this.$store.dispatch('account/getClaims');
      this.$http
        .get('/profiles')
        .then(async (response) => {
          if (accountClaims.tutor) {
            this.$store.commit('account/setIsTutor', true);
            this.$store.commit(
              'account/setIsTutorCompleteRegistration',
              accountClaims.tutorRegistrationCompleted,
            );
            localStorage.setItem(
              'account/isTutorCompleteRegistration',
              (accountClaims.tutorRegistrationCompleted || false).toString(),
            );
            localStorage.setItem('account/isTutor', true.toString());
          } else {
            this.$store.commit('account/setIsTutor', false);
            localStorage.setItem('account/isTutor', false.toString());
          }
          const profiles = response.data;
          const followAsTutor = async (
            profileSet: Profile[],
          ): Promise<void> => {
            await this.checkOnboardingStatus();
            const tutorProfile = profileSet.find(
              (profile) => profile.role === ProfileType.TEACHER,
            );
            this.$store.commit('profile/setProfile', tutorProfile);
            localStorage.setItem('profile', JSON.stringify(tutorProfile));
            document.cookie = `profile=${JSON.stringify(
              tutorProfile,
            )}; path=/; domain=${this.$config.websiteUrl.replace(
              'https://',
              '',
            )}`;
            this.$store.commit('account/setIsTutorCompleteRegistration', true);
            this.$store.commit('account/setIsTutor', true);
            localStorage.setItem(
              'account/isTutorCompleteRegistration',
              true.toString(),
            );
            localStorage.setItem('account/isTutor', true.toString());
            if (this.onboardingStatus === ONBOARDING_STATUS.ACCEPTED) {
              this.$router.push({ path: `${this.localePath('Dashboard')}/` });
            } else if (onboardingStatuses.includes(this.onboardingStatus)) {
              this.$router.push({ path: `${this.localePath('Onboarding')}/` });
            }
          };
          localStorage.setItem(
            'profile/profiles',
            JSON.stringify(response.data),
          );
          this.$store.commit('profile/setProfiles', profiles);
          const { redirectTo } = this;
          if (
            (profiles.find((profile) => profile.role === ProfileType.TEACHER) ||
              accountClaims.tutor) &&
            !accountClaims.tutorRegistrationCompleted
          ) {
            if (profiles.length > 0) {
              this.$store.commit('profile/setProfile', profiles[0]);
              localStorage.setItem('profile', JSON.stringify(profiles[0]));
              document.cookie = `profile=${JSON.stringify(
                profiles[0],
              )}; path=/; domain=${this.$config.websiteUrl.replace(
                'https://',
                '',
              )}`;
            }
            this.$store.commit('account/setIsTutorCompleteRegistration', false);
            localStorage.setItem(
              'account/isTutorCompleteRegistration',
              false.toString(),
            );
            if (this.$store.state.account.isTutorCompleteRegistration) {
              this.$router.push({ path: `${this.localePath('Dashboard')}/` });
            } else if (localStorage.getItem('isLoggingFirstTime') === null) {
              this.$router.push({
                path: `${this.localePath('WelcomeScreenTeacher')}/`,
              });
            } else {
              this.$router.push({ path: `${this.localePath('Onboarding')}/` });
            }
          } else if (
            accountClaims.tutor &&
            accountClaims.tutorRegistrationCompleted
          ) {
            const tutors = profiles.filter(
              (x: Profile) => x.role === ProfileType.TEACHER,
            );
            if (tutors.length) {
              followAsTutor(tutors);
            }
          } else if (
            (this.profiles.length === 1 && this.profiles[0].name === null) ||
            this.profiles.length === 0
          ) {
            this.$router.push({ path: `${this.localePath('WelcomeScreen')}/` });
          } else if (this.redirectTo !== '/') {
            const clientProfileArray = profiles.filter(
              (x) => x.role === ProfileType.CLIENT,
            );
            if (clientProfileArray.length > 0) {
              if (!this.isAccountData && !redirectTo.includes('individual')) {
                this.$store.commit('common/setRedirectTo', redirectTo);
                this.$store.commit('common/setIsValidated', true);
                this.$router.push({
                  path: `${this.localePath('PaymentForm')}/`,
                });
              } else if (redirectTo) {
                this.$store.commit('common/setIsValidated', false);
                this.$router.push(redirectTo);
              }
            } else if (typeof this.$route.query.setProfile === 'string') {
              const url = new URL(this.$route.query.setProfile);
              this.$router.push({
                path: url.pathname,
                query: {
                  setProfile: this.$route.query.setProfile,
                },
              });
            } else {
              this.$router.push({ path: `${this.localePath('Profiles')}/` });
            }
          } else if (typeof this.$route.query.setProfile === 'string') {
            const url = new URL(this.$route.query.setProfile);
            this.$router.push({
              path: url.pathname,
              query: {
                setProfile: this.$route.query.setProfile,
              },
            });
          } else if (
            this.trialDataToRedirect &&
            this.trialDataToRedirect?.courseId
          ) {
            await this.fetchOws();
            if (!this.showOwsPopup) {
              this.$store.commit('profile/setProfile', profiles[0]);
              this.redirectToEnrollAfterTrial(
                this.trialDataToRedirect.courseId,
              );
            } else {
              this.$store.commit(
                'common/setRedirectTo',
                `/${this.$i18n.locale}/individual/enroll-after-trial/${this.trialDataToRedirect.courseId}`,
              );
              this.$router.push({ path: `${this.localePath('Profiles')}/` });
            }
          } else {
            this.$router.push({ path: `${this.localePath('Profiles')}/` });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async signInWithEmailAndPassword() {
      this.loading = true;
      (this.$refs.form as Vue & { validate: () => boolean }).validate();
      if (!this.valid) {
        this.loading = false;
        return;
      }

      const payload: Payloads = {
        email: this.email,
        password: this.password,
      };

      try {
        await this.$store.dispatch('account/signIn', payload);
        await this.fetchCompletedTrials();
        const redirectToIndividual = (
          this.$route.query.redirectTo || ''
        ).toString();
        if (redirectToIndividual) {
          this.$store.commit('common/setRedirectTo', redirectToIndividual);
        }
      } catch (error) {
        if (error instanceof Error && error.message === 'email not verified') {
          this.accountActivationSnackbar = true;
        } else if (
          error instanceof Error &&
          error.message === 'user disabled'
        ) {
          Vue.notify({
            text: this.$t('PAGES.LOGIN.USER_DISABLED').toString(),
            type: 'error',
          });
        } else {
          Vue.notify({
            text: this.$t('PAGES.LOGIN.BAD_DATA').toString(),
            type: 'error',
          });
        }
      }
      this.loading = false;
    },
    signInWithGoogle() {
      this.$store.dispatch('account/signIn', null);
    },
    enrollHandler() {
      this.profilesForGrade = this.profiles.filter(
        (x: Profile) =>
          x.grade === this.enrollProfile.grade &&
          x.name === this.enrollProfile.name,
      );
      if (this.profilesForGrade.length === 1) {
        this.$http
          .post('/reservations/prebook/finish', {
            token: this.token,
            student: { profileId: this.profilesForGrade[0].id },
          })
          .then(() => {
            this.loading = false;
            this.$store.commit('common/setToken', '');
            this.$router.push({ path: `${this.localePath('EnrollSuccess')}/` });
          });
      } else {
        this.$router.push({
          path: `${this.localePath('EnrollChooseProfile')}/`,
        });
      }
    },
    async sendAccountActivationEmail() {
      (this.$refs.form as Vue & { validate: () => boolean }).validate();
      if (!this.valid) {
        Vue.notify({
          text: this.$t('PAGES.LOGIN.ENTER_VALID_EMAIL').toString(),
          type: 'error',
        });
        return;
      }
      try {
        this.loadingSendAccountActivationEmail = true;
        await this.$http.post('/auth/verify', { email: this.email.trim() });
        this.accountActivationSnackbar = false;
        Vue.notify({
          text: this.$t('PAGES.LOGIN.LINK_SENDED').toString(),
          type: 'success',
        });
      } catch (error) {
        Vue.notify({
          text: this.$t('PAGES.LOGIN.SOMETHING_BAD').toString(),
          type: 'error',
        });
      } finally {
        this.loadingSendAccountActivationEmail = false;
      }
    },
    async checkOnboardingStatus() {
      await this.$http
        .get('/tutors/onboarding-status')
        .then((response) => {
          this.onboardingStatus = response.data.onboardingStatus;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async fetchCompletedTrials() {
      await this.$http
        .get('/group-enrollment/completed-trials')
        .then((response) => {
          this.completedTrialsData = response.data;
          this.trialDataToRedirect = this.completedTrialsData.find(
            (trialData: AfterTrialService) => trialData.shouldRedirect === 1,
          );
        })
        .catch((error) => {
          console.log(error);
        });
    },
    redirectToEnrollAfterTrial(courseId: number) {
      const url = `/${this.$i18n.locale}/individual/enroll-after-trial/${courseId}`;
      this.$router.push(url);
    },
    async fetchOws() {
      this.loading = true;
      await this.$http
        .get('/accounts/ows')
        .then((response) => {
          const {
            mgmPolicy,
            owsTutore,
            owsTutorePoland,
            policyTutore,
            policyTutorePoland,
            rules,
          } = response.data.owsData;
          const { hasZz, needsToBeUpdated } = response.data;
          if (owsTutore === undefined || policyTutore === undefined) {
            this.owsData = {
              mgmPolicy: !!mgmPolicy,
              owsTutorePoland: !!owsTutorePoland,
              policyTutorePoland: !!policyTutorePoland,
              rules: !!rules,
            };
            if (needsToBeUpdated) {
              this.owsData = {
                owsTutorePolandToUpdate: false,
                owsMainTutorePolandToUpdate: false,
                contractWithdrawal: false,
              };
              this.showOwsPopup = true;
            } else if (
              hasZz &&
              (!this.owsData.owsTutorePoland ||
                !this.owsData.policyTutorePoland)
            ) {
              this.showOwsPopup = true;
            }
          } else if (
            owsTutorePoland === undefined ||
            policyTutorePoland === undefined
          ) {
            this.owsData = {
              mgmPolicy: !!mgmPolicy,
              owsTutore: !!owsTutore,
              policyTutore: !!policyTutore,
              rules: !!rules,
            };
            if (needsToBeUpdated) {
              this.owsData = {
                owsTutoreToUpdate: false,
                owsMainTutoreToUpdate: false,
                contractWithdrawal: false,
              };
              this.showOwsPopup = true;
            } else if (
              hasZz &&
              (!this.owsData.owsTutore || !this.owsData.policyTutore)
            ) {
              this.showOwsPopup = true;
            }
          } else {
            this.owsData = {
              mgmPolicy: !!mgmPolicy,
              owsTutore: !!owsTutore,
              owsTutorePoland: !!owsTutorePoland,
              policyTutore: !!policyTutore,
              policyTutorePoland: !!policyTutorePoland,
              rules: !!rules,
            };
            if (needsToBeUpdated) {
              this.owsData = {
                owsTutoreToUpdate: false,
                owsTutorePolandToUpdate: false,
                owsMainTutoreToUpdate: false,
                owsMainTutorePolandToUpdate: false,
                contractWithdrawal: false,
              };
              this.showOwsPopup = true;
            } else if (
              hasZz &&
              (!this.owsData.owsTutore ||
                !this.owsData.owsTutorePoland ||
                !this.owsData.policyTutore ||
                !this.owsData.policyTutorePoland)
            ) {
              this.showOwsPopup = true;
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
});
